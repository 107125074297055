.col{
    padding:20px;
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    width: 100%;

    /* align-items: center; */
    background-color: var(--grey);

}
@media (min-width:800px) {
    .col{
        max-width: 600px;
    }
}
.col2{
    padding:20px;
    display: flex;
    flex-direction: column;
    /* width: 50%; */
   
    flex:1;
    /* justify-content: center; */
}
@media (min-width:800px) {
    .col2{
        width: 50%;
    }
}
.cont{
    width: 100%;

    height:100vh;
    display: flex;

}


.logocont{
    width:100%;
    /* flex:2; */

}
.nav{
    display: flex;
    height:25px;
    padding: 10px;
    align-items: center;

    width: 100%;
    justify-content: center;
}

.nav button{
    margin-left:10px;
    height:35px;
    font-weight: 500;
}

.btnsecondary{
    background:#00000000!important;
    border:none!important;
    width:fit-content!important;
    border-radius:100px!important;
    font-weight: 500!important;

}

.screenshotimgcont{
    position: relative;
}

.screenshotimg{
    border: 1px solid var(--bordercolor);
    overflow:hidden;
    border-radius:16px;
}

.screenshotimg#player{
    position: absolute;
    bottom:50px;
    border-radius: 4px;
    left:-10px;
}

.slidedescr{
    margin-top:30px;
    margin-bottom:30px;
}
.navbutcont{
    width:20px;
    display: flex;
    min-width: 180px;
    /* flex:1; */
    align-items:flex-end;
    border-radius:100px;
    justify-content: center;
}


.hero{
    height:100%;
    display: flex;
    justify-content: center;
    align-items:center;
   


    flex-direction:column;
    max-width: 600px;
    padding:20px;
    width:100%;

}

@media (max-width:800px) {
    .col{
        align-items: center;
    }
    .hero{
        text-align: center;
    }
}
@media (max-width:600px) {
    .col{
        align-items: flex-start;
    }
    .hero{
        text-align: left;
    }
}
.herobuttoncont{
    margin-top:20px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
}



@media (max-width:600px) {
    .herobuttoncont{
        margin-top:20px;
        margin-bottom: -10px;
    }
    .hero h1{
        font-size: 33px!important;
    }

    .hero h2{
        font-size: 22px!important;
    }
}

.slider{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.slide{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom:60px;
    width: 100%;
    background: var(--bodybg);

    height: 100%;
    justify-content: center;
}

@media (min-width:800px) {
   
    /* .cont{
        max-width: 700px;
    } */
}
@media (max-width:800px) {
    .herobuttoncont button{
        max-width: 500px;
    }
    .cont{
        flex-direction: column;
    }

    .hero{
        padding:0px;
        margin-top:50px;
        margin-bottom:20px;

    }
    .nav{
        padding: 0px;
    }
}


